<template>
  <div>
    <div v-if="can_access">
      <b-card>
        <FilterRequestList
          :notification-selected="notificationSelected"
          droit="mes_demandes"
          @onSearchFromChild="onSearch"
        />
      </b-card>
      <b-overlay
        :show="loading"
      >
        <b-row>
          <b-col v-if="!errorHttp">
            <component
              :is="activeComponent"
              :ticket-list="rows"
              :total-item-from-parent="totalItem"
              :reset-page-from-parent="resetPage"
              @onSearchFromTable="onSearch"
            />
          </b-col>
          <b-col v-else>
            {{ $t('ERREURS.CHARGEMENT_DES_DONNEES') }}
          </b-col>
        </b-row>
      </b-overlay>
    </div>
    <b-card v-else>
      <notAuthorized />
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay, BCard, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import NotAuthorized from '@/views/auth/NotAuthorized.vue'
import FilterRequestList from './FilterRequestList.vue'
import TableRequestList from './TableRequestList.vue'
import CardRequestList from './CardRequestList.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    TableRequestList,
    CardRequestList,
    FilterRequestList,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    NotAuthorized,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      can_access: false,
      activeComponent: '',
      isSmall: false,
      notificationSelected: '',
      currentPageFromTable: 1,
      resetPage: false,
      globalParams: {
        societe: '',
        etat: '',
        urgence: '',
        limit: 10,
        page: 1,
        recherche: '',
        date_debut: '',
        date_fin: '',
      },
      errorHttp: false,
      totalItem: '0',
      limit: 10,
      loading: false,
      dir: false,
      rows: [],
    }
  },
  computed: {
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  watch: {
    can_access() {
      if (this.can_access) {
        this.getFilterRequestList(this.globalParams)
      }
    },
    isSmall(newValue) {
      this.activeComponent = newValue ? 'CardRequestList' : 'TableRequestList'
      this.globalParams = JSON.parse(localStorage.getItem('formatedFilter'))
      this.globalParams.limit = this.isSmall ? 5 : this.limit
      this.getFilterRequestList(this.globalParams)
    },
    activeSociete() {
      this.authorized()
      this.formatGlobalParams()
    },
  },
  mounted() {
    this.authorized()
    this.formatGlobalParams()

    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    if (window.innerWidth < 1600) {
      this.activeComponent = 'CardRequestList'
      this.globalParams.limit = 5
    } else {
      this.activeComponent = 'TableRequestList'
      this.globalParams.limit = this.limit
    }

    // Récupération depuis le store du statut enregistré suite à un clic sur l'une des cloches
    this.notificationSelected = this.$store.getters['hotline/getRequestStatusSelected'].status

    // Ajout d'un watcher sur le store au montage de la vue.
    // Celui-ci permet catcher la valeur transmise par le store concernant le "Statut" demandé
    // pour appeler l'API et mettre à jour le tableau avec le filtre nouvellement défini
    this.$unwatch = this.$store.watch(
      (state, getters) => getters['hotline/getRequestStatusSelected'],
      (newValue, oldValue) => {
        if (oldValue.status !== newValue.status || this.globalParams.etat.length > 1) {
          this.globalParams.etat = this.$store.getters['hotline/getRequestStatusSelected'].status
          this.notificationSelected = this.$store.getters['hotline/getRequestStatusSelected'].status
          this.getFilterRequestList(this.globalParams)
        }
      },
    )
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    // Démontage du watcher avant la destruction de la vue
    this.$unwatch()
    this.$store.dispatch('hotline/setRequestStatusSelected', { status: null })
  },
  methods: {
    formatGlobalParams() {
      if (localStorage.getItem('formatedFilter')) {
        // Récupération du filtre depuis le localStorage sous sa forme formatté `formatedFilter`
        this.globalParams = JSON.parse(localStorage.getItem('formatedFilter'))
      }
      // Définition de l'`idContact` et de la limite par page
      this.globalParams.contact = this.activeSociete.idContact
      // Vérification de la présence d'un statut sélectionné dans le store
      // Ce statut correspond à la valeur lors d'un clic sur l'une des icones
      // de notification (cloches en haut à droite)
      if (this.$store.getters['hotline/getRequestStatusSelected'].status) {
        // Définition du statut par rapport à celui de la cloche
        this.globalParams.etat = this.$store.getters['hotline/getRequestStatusSelected'].status
      }
    },
    authorized() {
      this.$store.dispatch('application/canAccess', { idSociete: this.activeSociete.idSociete, droit: 'mes_demandes' }).then(r => { this.can_access = r })
    },
    handleResize() {
      this.isSmall = window.innerWidth < 1600
    },
    /**
     * Méthode qui est déclenchée par l'event reçu du composant `FilterRequestList.vue` ou
     * par la vue `TableRequestList.vue`. Elle permet de déterminer si l'appel de la méthode
     * provient d'un clic sur le bouton **Rechercher** ou d'un changement de page ou du changement
     * du nombre de lignes à afficher.
     *
     * Si la page actuelle du tableau est différente de 1 alors on change la currentPage dans la
     * vue `TableRequestList.vue` qui (à l'aide d'un watch) va rappeler cette méthode en passant
     * un autre **params** qui lance la méthode `getFilterRequestList()`
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    onSearch(params) {
      if (params.paramsFromFilter) {
        this.globalParams.societe = params.paramsFromFilter.societe
      }
      // Le **params** vient de `FilterRequestList` et la page actuelle est différente de 1
      // Un nouveau filtre a été défini
      // => Changement de page
      if (params.paramsFromFilter && this.currentPageFromTable !== 1) {
        this.globalParams = { ...this.globalParams, ...params.paramsFromFilter }
        this.resetPage = !this.resetPage

      // Le **params** vient de `TableRequestList` et la page actuelle est différente de 1
      // La limite de résultats par page a été changée
      // => Changement de page
      } else if (params.from === 'fromLimit' && this.currentPageFromTable !== 1) {
        this.globalParams.limit = params.value
        this.resetPage = !this.resetPage

      // Le **params** vient de `TableRequestList` et la page actuelle égale à 1
      // La limite de résultats par page a été changée
      // => Appel de `getFilterRequestList()`
      } else if (params.from === 'fromLimit') {
        this.globalParams.limit = params.value
        this.getFilterRequestList(this.globalParams)

      // Le **params** vient de `TableRequestList` et la page actuelle égale à 1
      // Une nouvelle page a été demandée
      // => Appel de `getFilterRequestList()`
      } else if (params.from === 'fromPage') {
        this.currentPageFromTable = params.value
        this.globalParams.page = params.value
        this.getFilterRequestList(this.globalParams)

      // Le **params** vient de `FilterRequestList` et la page actuelle égale à 1
      // Un nouveau filtre a été défini
      // => Appel de `getFilterRequestList()`
      } else {
        this.globalParams = { ...this.globalParams, ...params.paramsFromFilter }
        this.getFilterRequestList(this.globalParams)
      }
    },

    /**
     * Méthode qui permet d'appeler le store `hotline` pour exécuter la requête http
     * de recherche avec les paramètres voulus.
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    getFilterRequestList(params) {
      if (this.can_access) {
        this.loading = true
        const finalParams = params

        // Les lignes qui suivent jusqu'au store.dispatch servent à définir la liste
        // des sociétés à requêter ainsi que leur `idContact` correspondant
        const userDatas = this.$store.getters['utilisateur/getUserDatas']
        const societeArray = finalParams.societe ? finalParams.societe.split(',') : [this.activeSociete.idSociete.toString()]

        finalParams.societe = societeArray.join(',')

        const contactArray = []
        userDatas.forEach(element => {
          if (finalParams.societe.split(',').includes(element.idSociete.toString())) {
            contactArray.push(element.idContact)
          }
        })
        finalParams.contact = contactArray.length ? contactArray.join(',') : this.activeSociete.idContact

        this.$store.dispatch('hotline/getEntityRequestList', finalParams)
          .then(
            result => {
              this.totalItem = result.total
              this.rows = result.data
            },
          ).catch(
            () => {
              this.error()
              this.errorHttp = true
            },
          ).finally(() => {
            this.loading = false
          })
      }
    },

    /**
     * Méthode qui ouvre une modal d'erreur si la récupération des données est impossible.
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    error() {
      this.$swal({
        title: this.$t('ERREURS.GENERAL'),
        text: this.$t('ERREURS.CHARGEMENT_DES_DONNEES'),
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    /**
     * Méthode permettant de formater la date à afficher dans le tableau
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    formatDate(date) {
      return this.$luxon(date, 'dd/MM/yyyy')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
#detailIcon {
  cursor: pointer
}
</style>
